import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'swiper/angular';
import { CategoriessectiontwoComponent } from './homeone/categoriessectiontwo/categoriessectiontwo.component';
import { FooteroneComponent } from './common/footer/footerone/footerone.component';
import { FootertwoComponent } from './common/footer/footertwo/footertwo.component';
import { HeaderoneComponent } from './common/header/headerone/headerone.component';
import { MobilemenuComponent } from './common/header/mobilemenu/mobilemenu.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxLiteYoutubeModule } from 'ngx-lite-video'
import { MatFormFieldModule } from '@angular/material/form-field';
import { JejakimaniSafeHtmlPipe } from '../helpers/jejakimani-safe-html';
import { JejakImaniTitlePipe } from '../helpers/jejakimani-title.pipe';
import { JejakImaniStripHtmlPipe } from '../helpers/jejakimani-strip-html.pipe';
import { PhoneNumberPipe } from '../helpers/phone-number.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { CounterInputComponent } from './common/counter-input/counter-input.component';
import { UcwordsPipe } from '../helpers/ucwords';

// New Componen
import { EditorContentComponent } from './common/editor-content/editor-content.component';
import { HeroComponent } from './common/header/hero/hero.component';
import { FeatureComponent } from './common/feature/feature.component';
import { TestimonialWithVideoComponent } from './common/testimonial-with-video/testimonial-with-video.component';
import { FacilitiesComponent } from './common/facilities/facilities.component';
import { ContactFormComponent } from './common/contact-form/contact-form.component';
import { LocationWithMapComponent } from './common/location-with-map/location-with-map.component';
import { FaqComponent } from './common/faq/faq.component';
import { ContactSalesComponent } from './common/contact-sales/contact-sales.component';
import { ProductSliderComponent } from './common/product-slider/product-slider.component';
import { ProductOneComponent } from './common/product/product-one/product-one.component';
import { ProductTwoComponent } from './common/product/product-two/product-two.component';
import { ProductOtherComponent } from './common/product/product-other/product-other.component';
import { FooterParagraphComponent } from './common/footer-paragraph/footer-paragraph.component';
import { EditorSeoComponent } from './common/editor-seo/editor-seo.component';
import { EditorButtonComponent } from './common/editor-button/editor-button.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { EditorProductComponent } from './common/editor-product/editor-product.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SliderComponent } from './common/header/hero-backup/hero.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownModule,
    HttpClientModule,
    SwiperModule,
    EditorModule,
    NgOptimizedImage,
    NgxLiteYoutubeModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  declarations: [
    CategoriessectiontwoComponent,
    FooteroneComponent,
    FootertwoComponent,
    HeaderoneComponent,
    MobilemenuComponent,
    JejakImaniTitlePipe,
    JejakImaniStripHtmlPipe,
    JejakimaniSafeHtmlPipe,
    PhoneNumberPipe,
    CounterInputComponent,
    UcwordsPipe,

    // New
    EditorContentComponent,
    HeroComponent,
    FeatureComponent,
    TestimonialWithVideoComponent,
    FacilitiesComponent,
    ContactFormComponent,
    LocationWithMapComponent,
    FaqComponent,
    ContactSalesComponent,
    FooterParagraphComponent,
    // Product
    ProductSliderComponent,
    ProductOneComponent,
    ProductTwoComponent,
    ProductOtherComponent,
    // Editor
    EditorButtonComponent,
    EditorContentComponent,
    EditorSeoComponent,
    EditorProductComponent,
    SliderComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownModule,
    HttpClientModule,
    SwiperModule,
    EditorModule,
    NgOptimizedImage,
    NgxLiteYoutubeModule,
    NgxPaginationModule,
    CategoriessectiontwoComponent,
    FooteroneComponent,
    FootertwoComponent,
    HeaderoneComponent,
    MobilemenuComponent,
    JejakImaniTitlePipe,
    JejakImaniStripHtmlPipe,
    JejakimaniSafeHtmlPipe,
    PhoneNumberPipe,
    CounterInputComponent,
    UcwordsPipe,

    // New
    EditorContentComponent,
    HeroComponent,
    FeatureComponent,
    TestimonialWithVideoComponent,
    FacilitiesComponent,
    ContactFormComponent,
    LocationWithMapComponent,
    FaqComponent,
    ContactSalesComponent,
    FooterParagraphComponent,
    // Product
    ProductSliderComponent,
    ProductOneComponent,
    ProductTwoComponent,
    ProductOtherComponent,
    // Editor
    EditorButtonComponent,
    EditorContentComponent,
    EditorSeoComponent,
    EditorProductComponent,
    SliderComponent
  ]
})
export class JejakImaniModule { }
